import React, { useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-r";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/ext-language_tools";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import SortIcon from '@mui/icons-material/Sort';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ACCESS_LEVEL } from "utility/constants/constants";
import Tooltip from '@mui/material/Tooltip';
import * as commonService from "../../../utility/CommonService"
import { useDispatch, useSelector } from "react-redux";
import 'brace/ext/searchbox';
import OpenInFull from "@mui/icons-material/OpenInFull";
import { CloseFullscreen, OpenInBrowser } from "@mui/icons-material";
import * as actions from '../../../redux/actions/ProjectsActions/action'
import TerminalIcon from '@mui/icons-material/Terminal';
import { setPermissionTooltip } from "utility/utility";

const CodeEditor = ({ startContainer, handleShowEnvVariables, languageSelection, handleShowLogs, onCommit, handleSetFullScreen, fullScreen, handleShowTerminal, accessLevel, disableIcon}) => {
  const { previewContainerInfo } = useSelector((state) => state.appReducer)
  const { projectFilesContent, currentFilePath, dockerImages, project} = useSelector((state) => state.projectsReducer)
  const dispatch = useDispatch();

  const onChange = (newValue) => {
    dispatch(actions.setProjectFileContent(currentFilePath, newValue))
  };

  const handleImageChange = (e) => {
    if (project.image_id !== e.target.value) {
      let body = { image_id: e.target.value !== "" ? e.target.value : null }
      dispatch(actions.updateProject({body: {entity: body}, id: project.id}))
    }
  }
  useEffect(() => {
    dispatch(actions.listDockerImages())
  }, [dispatch])


  const renderContent = () => {
      return projectFilesContent[currentFilePath]?.code
  }

  const showWebView = () => {
    if (!previewContainerInfo.containerId)
      return commonService.forError("Please run your code to see web view", "Error")

    window.open(previewContainerInfo.containerUrl, "_blank")
  }

  return (
    <>
      <div className="area_header">
        {fullScreen ? <CloseFullscreen onClick={handleSetFullScreen} /> : <OpenInFull onClick={handleSetFullScreen} />}
      </div>
      <div className="editor_toolbar">
        <div className="toolbar_left">
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EXECUTE, accessLevel, "Run")}>
            <span>
              <PlayCircleFilledWhiteOutlinedIcon className={disableIcon(["view"], accessLevel)} onClick={() => startContainer().then(() => {
                handleShowLogs(true)
              })} />
            </span>
          </Tooltip>
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EDIT, accessLevel, "Secrets")}>
            <span>
              <VpnKeyOutlinedIcon className={disableIcon(["view", "execute"], accessLevel)} onClick={handleShowEnvVariables} />
            </span>
          </Tooltip>
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EXECUTE, accessLevel, "Logs")}>
            <span>
              <SortIcon className={disableIcon(["view"], accessLevel)} onClick={() => {
                if (previewContainerInfo.containerId)
                  handleShowLogs()
                else {
                  commonService.forError(
                    "Please run your code to see logs",
                    "Error"
                  );
                }
              }} />
            </span>
          </Tooltip>
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EDIT, accessLevel, "Commit")}>
            <span>
              <SaveOutlinedIcon className={disableIcon(["view", "execute"], accessLevel)} onClick={() => onCommit()} />
            </span>
          </Tooltip>
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EXECUTE, accessLevel, "Web View")}>
            <span>
              <OpenInBrowser className={disableIcon(["view"], accessLevel)} onClick={showWebView} />
            </span>
          </Tooltip>
          <Tooltip title={setPermissionTooltip(ACCESS_LEVEL.EXECUTE, accessLevel, "Terminal")}>
            <TerminalIcon className={disableIcon(["view"], accessLevel)} onClick={() => {
                if (previewContainerInfo.containerId)
                  handleShowTerminal()
                else {
                  commonService.forError(
                    "Please run your code to use the terminal",
                    "Error"
                  );
                }
                }} />
          </Tooltip>
        </div>
        <div className="toolbar_right">
          <select
            className="options_select"
            name="options"
            value={project.image_id || ""}
            onChange={handleImageChange}
          >
            <option value="">{"Select an Image"}</option>
            {dockerImages.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="ace_wrapper">
        <AceEditor
          mode={languageSelection}
          width="100%"
          height="100%"
          value={renderContent()}
          onChange={onChange}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          highlightActiveLine={true}
          showPrintMargin={false}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            useWorker: false,
            readOnly: ["view", "execute"].includes(accessLevel) // need edit access to change code
          }}
        />
      </div>
    </>
  );
};

export default CodeEditor;
