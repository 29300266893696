import { AuthActionTypes } from "../actions/AuthActions/actionType";
import storage from "../../utility/storage";

// const token = storage.get("token", null);
// const refresh_token = storage.get("refresh_token", null);
// const user = storage.get("user", null);

export const initialState = {
  token: null,
  refresh_token: null,
  user: null,
  isloading: false,
  isAdmin: false,
  resetPasswordToken: null,
  verified_2fa: false,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  };
};
const setUserData = (payload) => {
  storage.set("user", payload.user);
  storage.set("token", payload.token);
  storage.set("refresh_token", payload.refresh_token);
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_PENDING:
      return updateObject(state, { isloading: true, disabled: true });
    case AuthActionTypes.LOGIN_FULFILLED:
      setUserData(action.payload);
      return updateObject(state, {
        isloading: false,
        disabled: false,
        user: action.payload.user,
        token: action.payload.token
      });
    case AuthActionTypes.REFRESH_TOKEN_FULFILLED:
      storage.set("token", action.payload.token);
      storage.set("refresh_token", action.payload.refresh_token);
      return updateObject(state, {
        isloading: false,
        disabled: false,
        token: action.payload.token
      });

    case AuthActionTypes.SIGNUP_PENDING:
      return updateObject(state, { isloading: true, disabled: true });
    case AuthActionTypes.SIGNUP_FULFILLED:
      setUserData(action.payload);
      return updateObject(state, {
        isloading: false,
        disabled: false,
        user: action.payload.user,
        token: action.payload.token
      });

    case AuthActionTypes.FORGOT_PASSWORD_PENDING:
      return updateObject(state, { isloading: true, disabled: true });
    case AuthActionTypes.FORGOT_PASSWORD_FULFILLED:
      return updateObject(state, { isloading: false, disabled: false });

    case AuthActionTypes.RESET_PASSWORD_PENDING:
      return updateObject(state, { isloading: true, disabled: true });
    case AuthActionTypes.RESET_PASSWORD_FULFILLED:
      return updateObject(state, { isloading: false, disabled: false });

    case AuthActionTypes.AUTHORIZE:
      const payload = action.payload;
      const isAdmin = payload.user_profile.role === "superadmin" ? true : false;
      return updateObject(state, {
        token: payload.token,
        user: payload.user_profile,
        refresh_token: payload.refresh_token,
        isAdmin: isAdmin
      });

    case AuthActionTypes.LOGOUT:
      return updateObject(state, {
        token: null,
        refresh_token: null,
        user: null,
        isloading: false,
        isAdmin: false,
        impersonate: false,
        verified_2fa: false,
      });

    case AuthActionTypes.UPDATE_USER_FULFILLED:
      storage.set("user", action.payload.user);
      return updateObject(state, {
        user: action.payload.user,
      });
    case AuthActionTypes.GIT_DETAILS_FULFILLED:
      storage.set("user", action.payload.user);
      return updateObject(state, {
        user: action.payload.user
      });
    case AuthActionTypes.ENABLE_2FA_FULFILLED:
      storage.set("user", action.payload.user);
      return updateObject(state, {
        user: action.payload.user
      });
    case AuthActionTypes.VERIFY_2FA_FULFILLED:
      storage.set("token", action.payload.token);
      storage.set("refresh_token", action.payload.refresh_token);
      storage.set("user", action.payload.user);
      return updateObject(state, {
        user: action.payload.user,
        verified_2fa: action.payload.verified_2fa,
        token: action.payload.token
      });
    default:
      return state;
  }
};
