import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getAppMetricsByOrg
} from "../../../redux/actions/MetricsActions/Action";
import TimeRangeIndicator
  from "../../shared/Graph/TimeRangeIndicator/TimeRangeIndicator";
import StackedBarGraph from "../../shared/Graph/StackedBarGraph";
import EmptyGraph from "../../shared/Graph/EmptyGraph";
import Loader from "../../shared/Loader/Loader";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton } from "@material-ui/core";
import { ENTITY_TYPES } from "../../../utility/constants/constants";
import { CloseFullscreen } from "@mui/icons-material";

const InsightsUsageGraph = ({organizationId, graphTitle, graphOptions, expand, handleExpand, handleCollapse}) => {
  const dispatch = useDispatch();
  const appMetrics = useSelector((state) => state.metricsReducer.appMetrics);
  const [isAppMetricsLoaded, setIsAppMetricsLoaded] = useState(false);
  const [insightsUsageTimeRange, setInsightsUsageTimeRange] = useState('week')

  useEffect(() => {
    setIsAppMetricsLoaded(false)
    dispatch(getAppMetricsByOrg(organizationId, {
      period: insightsUsageTimeRange,
    }))
  }, [dispatch, insightsUsageTimeRange, organizationId])

  useEffect(() => {
    setIsAppMetricsLoaded(true)
  }, [appMetrics])

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setInsightsUsageTimeRange(newTime)
    }
  }

  return (
    <div className={expand ? "panel entity_usage_details_graph_container" : "panel entity_usage_graph_container"}>
      <div className={expand ? "panel_header_row expanded" : "panel_header_row"}>
        {graphTitle && <div className="panel_description no_margin">{graphTitle}</div>}
        <div className="time_range_expand_icon_group">
          <TimeRangeIndicator timeRange={insightsUsageTimeRange}
                              handleTimeChange={handleTimeChange}
                              className={"time_range_button_group align_items_end no_margin"} />
          {
            expand ?
              <IconButton onClick={() => handleCollapse()}>
                <CloseFullscreen></CloseFullscreen>
              </IconButton> :
              <IconButton onClick={() => handleExpand(ENTITY_TYPES.INSIGHT)}>
                <OpenInFullIcon></OpenInFullIcon>
              </IconButton>
          }
        </div>
      </div>
      <>
        {
          isAppMetricsLoaded ?
            <>
              {
                Object.keys(appMetrics).length > 0 ?
                  <StackedBarGraph data={appMetrics} options={graphOptions}
                                   barLabels={Object.values(appMetrics).map(value => value.name)}
                                   barPercentage={0.2}
                                   barSectionLabels={Object.keys(Object.values(appMetrics)[0]).filter(el => el !== 'name')}
                                   className={expand ? "admin_portal_graph" : "entity_usage_graph"} /> :
                  <div className={"empty_graph_container"}>
                    <EmptyGraph />
                  </div>
              }
            </> :
            <Loader loading={!isAppMetricsLoaded}></Loader>
        }
      </>
    </div>
  )
}

export default InsightsUsageGraph