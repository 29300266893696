import React, { useState, useEffect } from "react";
import timezones from "google-timezones-json";
// user action
import { getImageAccess, updateUserProfile } from "redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  Input,
  Button,
} from "components/shared/ui-components/form-components";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleOutlinedIcon
  from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";
import * as commonService from "utility/CommonService.js";
import { enable2FA } from "redux/actions/AuthActions";
import EnableTwoFactorAuth from "../Auth/TwoFactorAuth/EnableTwoFactorAuth";
import { CheckCircle } from "@mui/icons-material";

export const Form = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [accountForm, setAccountForm] = useState({ ...user });
  const [filePath, setFilePath] = useState("");
  const [is2faVerified, setIs2faVerified] = useState(user.confirmed_2fa);
  const dispatch = useDispatch();
  const dateFormatEnum = Object.freeze({
    "MM/DD/YY": true,
    "DD/MM/YY": true,
    "MMMM/DD/YY": true,
    "DD/MMMM/YY": true
  })
  const { date_format } = accountForm;
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setIs2faVerified(user.confirmed_2fa)
  }, [user.confirmed_2fa])

  const handleFile = (e) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      dispatch(getImageAccess({ user_name: user.email })).then(
        (res) => {
          let url = res.value.urls[res.value.urls.length - 1];
          if (url.presigned_url && url.photo_path) {
            commonService.isLoading.onNext(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            let requestOptions = {
              method: "PUT",
              headers: myHeaders,
              body: file,
            };
            fetch(url.presigned_url, requestOptions).then(
              (res) => {
                commonService.isLoading.onNext(false);
                setAccountForm({ ...accountForm, photo_path: url.photo_path });
              },
              (error) => commonService.isLoading.onNext(false)
            );
          }
          return res.value;
        }
      );
      let url = URL.createObjectURL(e.target.files[0]);
      setFilePath(url);
    }
  };

  const handleChange = (e) => {
    setAccountForm({ ...accountForm, [e.target.name]: e.target.value });
  };

  const handleVerify2fa = (status) => {
    setIs2faVerified(status);
  }

  const handle2FAChange = async (checked) => {
    return await dispatch(
      enable2FA({
        body: {
          enabled_2fa: checked
        }
      })
    )
  }
  const gitValidation = () => {
    if (accountForm.git_username || accountForm.git_token) {
      if (accountForm.git_username && accountForm.git_token) {
        return true;
      } else {
        commonService.forError(
          "Please enter both Github username and Github token"
        );
        return false;
      }
    }
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (gitValidation()) {
      let userForm;
      const {
        first_name,
        last_name,
        git_username,
        git_token,
        timezone,
        photo_path
      } = accountForm;
      if (typeof photo_path === "string")
        userForm = {
          photo_path,
          first_name,
          last_name,
          git_username,
          git_token,
          timezone
        };
      else
        userForm = { first_name, git_username, git_token, last_name, timezone };
      dispatch(
        updateUserProfile({
          body: {
            user: { ...userForm, date_format: date_format },
          },
          id: user.uid,
        })
      ).then((res) => {
        commonService.forSuccess("Profile updated successfully!", "Success");
      });
    }
  };

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="lds_ap_upload_img">
              <div className="avatar-edit">
                <div className="btnnn">
                  <AccountCircleOutlinedIcon
                    sx={{ fontSize: 100 }}
                    alt="img"
                    color="secondary"
                  />
                </div>
                <div className="avatar-preview profile">
                  {filePath ? (
                    <img
                      src={filePath}
                      alt="img-pict"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {!filePath && accountForm.photo_path ? (
                    <img
                      src={accountForm.photo_path.original}
                      alt="img-pict"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="pr_user_img_cont_wrapper">
                  <label htmlFor="imageUpload">Add Photo</label>
                  <Input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFile}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>First Name</h4>
              <Input
                type="text"
                placeholder="First Name"
                name="first_name"
                onChange={handleChange}
                value={accountForm.first_name || ""}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Last Name</h4>
              <Input
                type="text"
                placeholder="Last Name"
                name="last_name"
                onChange={handleChange}
                value={accountForm.last_name || ""}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Email</h4>
              <Input
                type="email"
                disabled
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={accountForm.email || ""}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Date Format</h4>
              <Select
                options={Object.keys(dateFormatEnum)}
                placeholder="dateFormat"
                name="date_format"
                onChange={(e) =>
                  handleChange({
                    target: {
                      value: e.value,
                      name: "date_format",
                    },
                  })
                }
                value={{
                  label: accountForm.date_format,
                  value: accountForm.date_format,
                }}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Time Zone</h4>
              <Select
                options={Object.keys(timezones)}
                placeholder="Timezone"
                name="timezone"
                onChange={(e) =>
                  handleChange({
                    target: { value: e.value, name: "timezone" },
                  })
                }
                value={{
                  label: accountForm.timezone,
                  value: accountForm.timezone,
                }}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Role</h4>
              <Select
                options={["Administrator", "Manager"]}
                placeholder="Role"
                name="role"
                isDisabled={true}
                onChange={(e) =>
                  handleChange({
                    target: { value: e.value, name: "role" },
                  })
                }
                value={{
                  label: accountForm.role,
                  value: accountForm.role,
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>Status</h4>
              <Input
                disabled
                placeholder="Status"
                name="status"
                onChange={(e) =>
                  handleChange({
                    target: { value: e.status, name: "status" },
                  })
                }
                value={accountForm.status}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_input">
              <h4>API Token</h4>
              <Input
                disabled
                type="text"
                name="api_token"
                value={user.api_key}
              />
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <h4 className="mb-3">Advanced Settings</h4>
            <a
              href="#/"
              type="collapse"
              role="button"
              data-toggle="collapse"
              data-target="#advancedSettings"
              aria-expanded="false"
              aria-controls="advancedSettings"
            >
              <div className="advanced_settings_btn">
                <ArrowDropDownIcon
                  fontSize="large"
                  color="disabled"
                  className="optionsIcon"
                />
                <SettingsOutlinedIcon
                  color="disabled"
                  sx={{ fontSize: 35 }}
                  className="cursor-pointer optionsIcon float-right"
                />
              </div>
            </a>
          </div>
          <div className="collapse col-md-12" id="advancedSettings">
            <div className="lds_login_form_input">
              <h4>Github Username</h4>
              <Input
                type="text"
                placeholder="Github Username"
                name="git_username"
                onChange={handleChange}
                value={accountForm.git_username || null}
              />
            </div>
            <div className="lds_login_form_input">
              <h4>Github Access Token</h4>
              <Input
                type="text"
                placeholder="Github Access Token"
                name="git_token"
                onChange={handleChange}
                value={accountForm.git_token || null}
              />
            </div>
            <Link to="/changepassword" className="mt-3 btn">
              Change Password
            </Link>
          </div>

          <div className="col-md-12 mt-4">
            <EnableTwoFactorAuth showModal={showModal}
                                 handleClose={handleClose}
                                 handleVerify2fa={handleVerify2fa}
            ></EnableTwoFactorAuth>
            <h4 className="mb-3">2 Factor Authentication</h4>
            {
              is2faVerified ? (
                <>
                  <div className="two_fa_verified">
                    <div className="check_circle">
                      <CheckCircle />
                    </div>
                    <div className="active">Active</div>
                  </div>
                  <div className="enable_two_fa_link" onClick={async (e) => {
                    e.preventDefault();
                    await handle2FAChange(false);
                    handleVerify2fa(false);
                  }}>
                    Disable
                  </div>
                </>
              ) : (
                <div className="enable_two_fa_link" onClick={async (e) => {
                  e.preventDefault();
                  await handle2FAChange(true);
                  setShowModal(true);
                }}>
                  Enable Two Factor Authentication
                </div>
              )
            }
          </div>
          <div className="col-md-12">
            <div className="lds_login_form_btn">
              <Button type="submit" className="btn submit_button">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
