import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { PUBLIC_ROUTES } from "config";

import Layout from "../hoc/Layout/Layout";
import { routes } from "../utility/constants/constants";
//public routes
import Login from "../screens/Auth/Login";
import ChangePassword from "../screens/Auth/ChangePassword";
import Home from "../screens/Home/Home";
import ProfileSetting from "../screens/Account/ProfileSetting";
import FlightSetting from "../screens/Account/FlightConfig";
import ProjectList from "../screens/Projects";
import ProjectDetails from "../screens/Projects/ProjectDetails";
import ProjectDetailsV2 from "../screens/ProjectsV2/ProjectDetailsV2"
import AddProject from "../screens/Projects/AddProject";
import EditProject from "../screens/Projects/EditProject";
import AddNoteBook from "../screens/NoteBooks/AddNoteBook";
import ImportNotebook from "../screens/NoteBooks/ImportNotebook";
import AddTemplate from "../components/Insights/InsightList/InsightTemplateCreation"
import EditForm from "../screens/NoteBooks/EditNoteBook";
import NotebookDetails from "../screens/NoteBooks/NotebookDetails";
import NotebookPreview from "../screens/NoteBooks/NotebookPreview";
import Insights from "../screens/Insights";
import DatasetDetails from "../screens/Datasets/DatasetDetails";
import AddDataset from "../screens/Datasets/AddDataset";
import EditDataset from "../screens/Datasets/EditDataset";
import AppDetails from "../screens/Insights/AppDetails";
import Users from "../screens/Users";
import CreateUser from "../screens/Users/CreateUser";
import EditUser from "../screens/Users/EditUser";
import Organizations from "../screens/Organizations";
import CreateOrganizations from "../screens/Organizations/CreateOrganizations";
import EditOrganizations from "../screens/Organizations/EditOrganizations";
import OrganizationAdmin from "../screens/Organizations/ManageOrganizations"
import Collections from "../screens/Web3/collections"
import AddCollections from "../screens/Web3/addCollections"
import TwoFactorAuth from "../screens/Auth/TwoFactorAuth";
import WorkflowDetails from "../screens/Workflows/WorkflowDetails";

import Web3 from "screens/Web3";
import ListPage from "../components/shared/ListPage";
import AdminPortal from "../screens/Admin";
import VisualWorkflows from "../screens/Workflows/VisualWorkflows"

const AppRouter = (props) => {
  const { token, verified_2fa } = useSelector((state) => state.authReducer);
  const verified = verified_2fa || !props.user?.enabled_2fa;
  const verifiedRender = (Component, props) => {
    if (verified) {
      return <Component {...props} />
    } else {
      return <Redirect to={routes.TWO_FACTOR_AUTH} />
    }
  }
  let routeList = (
    <Route
      render={(props) =>
        token && PUBLIC_ROUTES.includes(props.history.location.pathname) && ((props.user && props.user.enabled_2fa && verified_2fa) || (props.user && !props.user.enabled_2fa)) ? (
          <Redirect to={routes.HOME} />
        )
        : !token && !PUBLIC_ROUTES.includes(props.history.location.pathname) ? (
          <Redirect to={routes.LOGIN} />
        )
        : (
          <Switch>
            <Route
              exact
              path={routes.ROOT}
              render={(props) => (verifiedRender(Home, props))}
            />
            <Route
              exact
              path={routes.HOME}
              render={(props) => (verifiedRender(Home, props))}
            />
            <Route
              exact
              path={routes.PROFILE_SETTING}
              render={(props) => (verifiedRender(ProfileSetting, props))}
            />
            <Route
              exact
              path={routes.FLIGHT_CONFIG}
              render={(props) => (verifiedRender(FlightSetting, props))}
            />
            <Route
              exact
              path={routes.FLIGHT_CONFIG_ORGANIZATION}
              render={(props) => (verifiedRender(FlightSetting, props))}
            />
            <Route
              exact
              path={routes.PROJECT_LIST}
              render={(props) => (verifiedRender(ProjectList, props))}
            />
            <Route
              exact path={routes.PROJECT_V2_LIST}
              render={(props) => (verifiedRender(ListPage, props))}
            />
            <Route
              exact
              path={routes.ADD_PROJECT}
              render={(props) => (verifiedRender(AddProject, props))}
            />
            <Route
              exact
              path={routes.EDIT_PROJECT}
              render={(props) => (verifiedRender(EditProject, props))}
            />
            <Route
              exact
              path={routes.PROJECT_DETAILS}
              render={(props) => (verifiedRender(ProjectDetails, props))}
            />
            <Route
              exact
              path={routes.PROJECT_DETAILS_V2}
              render={(props) => (verifiedRender(ProjectDetailsV2, props))}
            />
            <Route
              exact
              path={routes.ADD_NOTE_BOOKS}
              render={(props) => (verifiedRender(AddNoteBook, props))}
            />
            <Route
              exact
              path={routes.EDIT_NOTE_BOOKS}
              render={(props) => (verifiedRender(EditForm, props))}
            />
            <Route
              exact
              path={routes.IMPORT_NOTE_BOOKS}
              render={(props) => (verifiedRender(ImportNotebook, props))}
            />
            <Route
              exact
              path={routes.ADD_TEMPLATE}
              render={(props) => (verifiedRender(AddTemplate, props))}
            />
            <Route
              exact
              path={routes.NOTE_BOOKS_DETAIL}
              render={(props) => (verifiedRender(NotebookDetails, props))}
            />
            <Route
              exact
              path={routes.PREVIEW_NOTE_BOOKS}
              render={(props) => (verifiedRender(NotebookPreview, props))}
            />
            <Route
              exact
              path={routes.INSIGHTS_LIST}
              render={(props) => (verifiedRender(Insights, props))}
            />
            <Route
              exact
              path={routes.DATASETS_LIST}
              render={(props) => (verifiedRender(ListPage, props))}
            />
            <Route
              exact
              path={routes.ADD_DATASET}
              render={(props) => (verifiedRender(AddDataset, props))}
            />
            <Route
              exact
              path={routes.EDIT_DATASET}
              render={(props) => (verifiedRender(EditDataset, props))}
            />
            <Route
              exact
              path={routes.DATASET_DETAILS}
              render={(props) => (verifiedRender(DatasetDetails, props))}
            />
            <Route
              exact
              path={routes.LOGIN}
              component={Login}
            />
            <Route
              exact
              path={routes.TWO_FACTOR_AUTH}
              component={TwoFactorAuth}
            />
            <Route
              exact
              path={routes.CHANGE_PASSWORD}
              render={(props) => (verifiedRender(ChangePassword, props))}
            />
            <Route
              exact
              path={routes.APP_DETAILS}
              render={(props) => (verifiedRender(AppDetails, props))}
            />
            <Route
              exact
              path={routes.CREATE_ORGANIZATIONS}
              render={(props) => verifiedRender(CreateOrganizations, props)}
            />
            <Route
              exact
              path={routes.EDIT_ORGANIZATIONS}
              render={(props) => verifiedRender(EditOrganizations, props)}
            />
            <Route
              exact
              path={routes.ORGANIZATIONS}
              render={(props) => verifiedRender(Organizations, props)}
            />
            <Route
              exact
              path={routes.MANAGE_ORGANIZATION}
              render={(props) => verifiedRender(OrganizationAdmin, props)}
            />
            <Route
              exact
              path={routes.COLLECTIONS}
              render={(props) => verifiedRender(Collections, props)}
            />
            <Route
              exact
              path={routes.ADD_COLLECTIONS}
              render={(props) => verifiedRender(AddCollections, props)}
            />
            <Route
              exact
              path={routes.USERS}
              render={(props) => verifiedRender(Users, props)}
            />
            <Route
              exact
              path={routes.CREATE_USER}
              render={(props) => verifiedRender(CreateUser, props)}
            />
            <Route
              exact
              path={routes.EDIT_USER}
              render={(props) => verifiedRender(EditUser, props)}
            />
            <Route
              exact
              path={routes.CRYPTO}
              render={(props) => (verifiedRender(Web3, props))}
            />
            <Route
              exact
              path={routes.WORKFLOWS}
              render={(props) => (verifiedRender(ListPage, props))}
            />
            <Route
              exact
              path={routes.WORKFLOW_DETAILS}
              render={(props) => (verifiedRender(WorkflowDetails, props))}
            />
            <Route
              exact
              path={routes.ADMIN_PORTAL}
              render={(props) => (verifiedRender(AdminPortal, props))}
            />
            <Route
              exact
              path={routes.VISUAL_WORKFLOWS}
              render={(props) => (verifiedRender(VisualWorkflows, props))}
            />
            <Route path="*" render={() => <Redirect to={routes.HOME} />} />
          </Switch>
        )
      }
    />
  );
  return <Layout>{routeList}</Layout>;
};

export default AppRouter;

export const NotFound = () => {
  return (
    <h1 className="text-center" style={{ margin: "100px" }}>
      404. Page not found.
    </h1>
  );
};
