import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as commonService from "../../../utility/CommonService";
import { Form } from "react-bootstrap";
import * as actions from "../../../redux/actions/AppActions";
import { Storage } from "aws-amplify";
import swal from "sweetalert";
import {
  Select,
  TextArea,
} from "components/shared/ui-components/form-components";
import { AWS_CONSTANTS } from "../../../utility/constants/constants";
import ImageSelector from "components/shared/ImageSelector";
import { uploadAssetImg, getAssetImgPath } from "utility/utility";

const AppTypes = {
  REPORT: "Report",
  APPLICATION: "Application",
};

const ImportInsightModal = ({ history, appTypeSort }) => {
  const defaultForm = {
    img_path: "",
    app_type: "",
    app_url: "",
    dataset_ids: [],
    description: "",
    name: "",
    file: "",
  };

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const { app } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [selectedImg, setSelectedImg] = useState();

  function Validation() {
    if (!form.name || !form.name.trim()) {
      commonService.forError("Empty name not allowed", "Error");
      return false;
    }
    if (!form.description || !form.description.trim()) {
      commonService.forError("Empty description not allowed", "Error");
      return false;
    }
    if (!form.app_type) {
      commonService.forError("Empty form type not allowed", "Error");
      return false;
    }
    if (form.app_type === AppTypes.REPORT) {
      if (!form.file) {
        commonService.forError("Attach a file", "Error");
        return false;
      }
    } else {
      if (!form.app_url || !form.app_url.trim()) {
        commonService.forError("Empty URL not allowed", "Error");
        return false;
      }
    }
    return true;
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onFileChange = (e) => {
    if (!e.nativeEvent.data || e.target.value.trim()) loadFile(e);
    return e;
  };

  const loadFile = (event) => {
    setForm({ ...form, file: event.target.files[0] });
  };

  const importReport = async () => {
    await uploadAssetImg(selectedImg, user);
    if (Validation()) {
      dispatch(
        actions.importReport({
          application: {
            dataset_ids: form.dataset_ids,
            description: form.description,
            name: form.name,
            file: form.file.name,
            img_path: getAssetImgPath(selectedImg, user),
          },
        })
      ).then(() => {
        commonService.isLoading.onNext(true);
        Storage.put(
          `${AWS_CONSTANTS.REPORTS_S3_PATH}/${app.id}/${form.file.name}`,
          form.file,
          {
            contentType: form.file.type,
          }
        )
          .then(() => {
            commonService.isLoading.onNext(false);
            swal({
              text: "Report has successfully imported.",
              className: "logout-alert-modal",
              buttons: [true, "Ok"],
            });
            setForm(defaultForm);
          }).then(() =>{
            dispatch(actions.getInsights( {appTypeSort} ));
          })
          .catch((err) => {
            swal({
              text: "File(s) had an error while uploading.",
              className: "logout-alert-modal",
              buttons: [true, "Ok"],
            });
          });
        setSelectedImg("");
        setShow(false);
      });
    }
  };

  const importApp = async () => {
    await uploadAssetImg(selectedImg, user);
    if (Validation()) {
      dispatch(
        actions.importApp({
          application: {
            app_url: form.app_url,
            name: form.name,
            description: form.description,
            img_path: getAssetImgPath(selectedImg, user),
          },
        })
      ).then(() => {
        commonService.forSuccess(
          `${form.app_type} Created Successfully`,
          "Success"
        );
        setShow(false);
        setSelectedImg("");
        setForm(defaultForm);
      }).then(() =>{
        dispatch(actions.getInsights( {appTypeSort} ));
      });
    }
  };

  return (
    <>
      <Button onClick={() => setShow(true)} className="btn">
        import
      </Button>

      <Modal
        className="import_modal"
        size="md"
        show={show}
        onHide={() => setShow(false)}
        animation={false}
      >
        <Modal.Header closeButton>Import Existing Application</Modal.Header>
        <Modal.Body>
          <form data-toggle="validator">
            <div className="form-img-selector">
              <ImageSelector
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
              />
            </div>
            <div className="col-md-12">
              <div className="publish_modal">
                <h4>Name</h4>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={form.name}
                  required
                  onChange={(e) => {
                    if (!e.nativeEvent.data || e.target.value.trim())
                      handleChange(e);
                    return e;
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="publish_modal lds_cp_box_heading">
                <h4>Description</h4>
                <TextArea
                  rows="4"
                  placeholder="Type Here"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  required
                ></TextArea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="publish_modal publish_select_btn">
                <h4>Publish As</h4>
                <Select
                  placeholder="Publish As"
                  name="app_type"
                  options={[AppTypes.REPORT, AppTypes.APPLICATION]}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        value: e.value,
                        name: "app_type",
                      },
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              {form.app_type === AppTypes.APPLICATION ? (
                <div className="publish_modal">
                  <h4>App URL</h4>
                  <input
                    type="text"
                    placeholder="Url"
                    name="app_url"
                    onChange={(e) => {
                      if (!e.nativeEvent.data || e.target.value.trim())
                        handleChange(e);
                      return e;
                    }}
                  />
                </div>
              ) : form.app_type === AppTypes.REPORT ? (
                <>
                  <div className="publish_modal">
                    <h4>Upload Report</h4>
                  </div>
                  &nbsp;
                  <div>
                    <Form.File
                      type="file"
                      name="file"
                      accept=".pdf"
                      multiple={false}
                      onChange={onFileChange}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={
              form.app_type === AppTypes.APPLICATION ? importApp : importReport
            }
            type="submit"
            value="Submit"
            className="btn float-right"
          >
            import
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportInsightModal;
