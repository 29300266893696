import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ErrorIcon from '@mui/icons-material/Error';

const ImportError = ({ workflow }) => {
  const [showError, setShowError] = useState(false);

  const toggleErrorModal = () => {
    setShowError(!showError);
  };

  return (
    <div>
      <div className="error-click" onClick={toggleErrorModal}>
        <span className="show-text">
            <ErrorIcon sx={{color:"#E7745A"}}/>
        </span>
      </div>

      <Dialog open={showError} onClose={toggleErrorModal}>
        <DialogTitle>Error Details</DialogTitle>
        <DialogContent>
          <div className="error-container">
            <div className="error-item">{workflow?.importError}</div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};


export default ImportError;
