import React, { useEffect, useState } from "react";
import * as actions from "../../../redux/actions/WorkflowActions";
import { useDispatch, useSelector } from "react-redux";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateVisualWorkflowModalV2 from "components/Workflows/WorkflowDetails/CreateVisualWorkflowModalV2";

const ProjectWorkflows = ({ projectId, setShowCreateWorkflowModal, accessLevel }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const { projectWorkflows } = useSelector((state) => state.workflowReducer);
  const [show, setShow] = useState(false)
  const [currentProjectWorkflow, setCurrentProjectWorkflow] = useState(null)
  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const handleMenuClick = (event, workflow) => {
    setCurrentProjectWorkflow(workflow)
    setMenuAnchor(event.currentTarget);
  };

  const handleDesignerClick = (workflow) => {
    history.push(`/visual-workflows/${workflow.id}`)
  }

  const handleWorkflowClick = (workflow) => {
    history.push(`/workflows/${workflow.id}`)
  }

  useEffect(() => {
    dispatch(actions.getProjectWorkflows({ projectId: projectId }));
  }, [dispatch, projectId]);

  return (
    <div className="project_workflows">
      <div className="linked_workflow_title">
        Linked Workflows
        {!["view"].includes(accessLevel) && (
          <Tooltip title="Initialize new workflow" placement="top">
            <AddCircleOutlineIcon onClick={() => setShow(true)} className="mr-1"/>
          </Tooltip>
        )}
        <CreateVisualWorkflowModalV2 show={show} onHide={() => setShow(false)} setShowCreateManualWorkflow={setShowCreateWorkflowModal} />
      </div>
      {projectWorkflows.map((workflow) => {
        const isVisualWorkflow = (workflow.nodes !== null || workflow.visual);
        return (
          <div className="workflow_item no_drop_shadow" key={workflow.id}>
            {isVisualWorkflow ? (
              <div className="material-symbols-outlined">
                auto_transmission
              </div>
            ) : (
              <SubtitlesOutlinedIcon />
            )}
            <Tooltip title={workflow.name} placement="top" enterDelay={500}>
              <div className="mx-2">{workflow.name}</div>
            </Tooltip>
            <div className="show_tool project_workflow_dropdown">
              <Tooltip title="More" placement="top">
                <IconButton
                  className="app_menu mr-1"
                  aria-label="more"
                  id="long-menu-button"
                  aria-controls={menuOpen ? "long-menu" : undefined}
                  aria-expanded={menuOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleMenuClick(e, workflow)}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      })}
      {projectWorkflows.length < 1 && (
        <div className="no_workflows">No workflows yet</div>
      )}
      {currentProjectWorkflow && <Menu
        id="long-menu-button"
        disableScrollLock={true}
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => handleMenuClose()}
      >
        {(currentProjectWorkflow.visual || currentProjectWorkflow.nodes !== null) && <MenuItem onClick={() => handleDesignerClick(currentProjectWorkflow)}>
          <ListItemIcon>
            <p>Open in Designer</p>
          </ListItemIcon>
        </MenuItem>}
        <MenuItem onClick={() => handleWorkflowClick(currentProjectWorkflow)}>
          <ListItemIcon className="delete">
            <p>View Workflow Details</p>
          </ListItemIcon>
        </MenuItem>
      </Menu>}
    </div>
  );
};

export default ProjectWorkflows;
