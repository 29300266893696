import { MetricsActionTypes } from "../actions/MetricsActions/ActionType";

export const initialState = {
  metrics: [],
  appMetrics: [],
  projectMetrics: [],
  datasetMetrics: [],
  workflowMetrics: []
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const metricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MetricsActionTypes.GET_ALL_METRICS_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_ALL_METRICS_FULFILLED:
      return updateObject(state, { metrics: action.payload.metrics });
    case MetricsActionTypes.GET_APP_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_APP_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { appMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_APP_METRICS:
      return updateObject(state, { appMetrics: [] });
    case MetricsActionTypes.GET_PROJECT_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_PROJECT_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { projectMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_PROJECT_METRICS:
      return updateObject(state, { projectMetrics: [] });
    case MetricsActionTypes.GET_DATASET_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_DATASET_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { datasetMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_DATASET_METRICS:
      return updateObject(state, { datasetMetrics: [] });
    case MetricsActionTypes.GET_WORKFLOW_METRICS_BY_ORG_PENDING:
      return updateObject(state, {});
    case MetricsActionTypes.GET_WORKFLOW_METRICS_BY_ORG_FULFILLED:
      return updateObject(state, { workflowMetrics: action.payload.metrics });
    case MetricsActionTypes.CLEAR_WORKFLOW_METRICS:
      return updateObject(state, { workflowMetrics: [] });
    default:
      return state;
  }
}