export const MetricsActionTypes = {
  GET_ALL_METRICS: "GET_ALL_METRICS",
  GET_ALL_METRICS_PENDING: "GET_ALL_METRICS_PENDING",
  GET_ALL_METRICS_FULFILLED: "GET_ALL_METRICS_FULFILLED",

  GET_APP_METRICS_BY_ORG: "GET_APP_METRICS_BY_ORG",
  GET_APP_METRICS_BY_ORG_PENDING: "GET_APP_METRICS_BY_ORG_PENDING",
  GET_APP_METRICS_BY_ORG_FULFILLED: "GET_APP_METRICS_BY_ORG_FULFILLED",

  CLEAR_APP_METRICS: "CLEAR_APP_METRICS",

  GET_PROJECT_METRICS_BY_ORG: "GET_PROJECT_METRICS_BY_ORG",
  GET_PROJECT_METRICS_BY_ORG_PENDING: "GET_PROJECT_METRICS_BY_ORG_PENDING",
  GET_PROJECT_METRICS_BY_ORG_FULFILLED: "GET_PROJECT_METRICS_BY_ORG_FULFILLED",

  CLEAR_PROJECT_METRICS: "CLEAR_PROJECT_METRICS",

  GET_DATASET_METRICS_BY_ORG: "GET_DATASET_METRICS_BY_ORG",
  GET_DATASET_METRICS_BY_ORG_PENDING: "GET_DATASET_METRICS_BY_ORG_PENDING",
  GET_DATASET_METRICS_BY_ORG_FULFILLED: "GET_DATASET_METRICS_BY_ORG_FULFILLED",

  CLEAR_DATASET_METRICS: "CLEAR_DATASET_METRICS",

  GET_WORKFLOW_METRICS_BY_ORG: "GET_WORKFLOW_METRICS_BY_ORG",
  GET_WORKFLOW_METRICS_BY_ORG_PENDING: "GET_WORKFLOW_METRICS_BY_ORG_PENDING",
  GET_WORKFLOW_METRICS_BY_ORG_FULFILLED: "GET_WORKFLOW_METRICS_BY_ORG_FULFILLED",

  CLEAR_WORKFLOW_METRICS: "CLEAR_WORKFLOW_METRICS",
}