import axios, { API_VERSION } from "../config";

export const getAllMetrics = (params) =>
  axios.get(`${API_VERSION}/metrics`, {params});

export const getAppMetricsByOrg = (orgId, params) =>
  axios.get(`${API_VERSION}/metrics/${orgId}/applications`, {params});

export const getProjectMetricsByOrg = (orgId, params) =>
  axios.get(`${API_VERSION}/metrics/${orgId}/projects`, {params});

export const getDatasetMetricsByOrg = (orgId, params) =>
  axios.get(`${API_VERSION}/metrics/${orgId}/datasets`, {params});

export const getWorkflowMetricsByOrg = (orgId, params) =>
  axios.get(`${API_VERSION}/metrics/${orgId}/workflows`, {params});