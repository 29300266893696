import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as projectActions from "redux/actions/ProjectsActions";
import HelpIconButton from "components/ProjectsV2/ProjectDetails/HelpIconButton";
import {Select} from "components/shared/ui-components/form-components";
import { Modal, Button } from "react-bootstrap";
import * as commonService from "utility/CommonService.js";
import { LOCATIONS } from "utility/constants/constants";
import * as projectsActions from "../../../redux/actions/ProjectsActions";

export const ProjectSelectModal = ({show, setShowCreateWorkflowModal, setShowProjectSelectModal, filterParams}) => {
  const dispatch = useDispatch();
  const {projects} = useSelector((state) => state.projectsReducer);
  const [selectedProject, setSelectedProject] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (show) {
      dispatch(projectActions.getProjects())
    } else {
      dispatch(projectsActions.getProjects(filterParams))
    }
  }, [dispatch, filterParams, show]);

  const handleProjectSelect = (selectedOption) => {
    setSelectedProject(selectedOption);
  }

  const handleProjectSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProject) {
      commonService.forError("Please select a project for the workflow to live in");
      return;
    }
    dispatch(
      projectActions.getProject({ id: selectedProject.value})
    ).then((res) => {
      if (res.value && res.value) {
        setShowProjectSelectModal(false);
        setShowCreateWorkflowModal(true);
      }
    })
  }

  return (
    projects &&
      <>
        <Modal
            className="import_modal"
            show={show}
            onHide={() => setShowProjectSelectModal(false)}
            animation={false}
            scrollable={true}
        >
          <Modal.Header closeButton>
            <div className="d-flex align-items-center">
              <h3>Select a project for the workflow</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="publish_modal publish_workflow ">
              <div className="workflow_header">
                <h4 className="publish_project_text">Select a Ludis project <span className="asterisk">*</span></h4>
                <HelpIconButton text="Please create a Ludis project if none appear in the dropdown below." />
              </div>
              <Select
                placeholder="Select Project"
                name="project"
                options={projects.map((item) => ({ name: item.name, id: item.id }))}
                value={selectedProject}
                onChange={handleProjectSelect}
                required
              />
              <h5 className="publish_workflow_text">Don't see the project you wish to select?</h5>
              <div className="left_align_button">
                <span className="initialize_workflow_button" onClick={() => {
                  history.push(`/${LOCATIONS.PROJECTS}`);
                  setShowProjectSelectModal(false);
                }}>
                  Create a project
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleProjectSubmit}
              type="submit"
              value="Submit"
              className="btn float-right"
            >
            next
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}