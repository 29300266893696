import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import * as projectActions from "../../../redux/actions/ProjectsActions";
import * as datasetActions from "../../../redux/actions/DataSetActions";
import * as workflowActions from "../../../redux/actions/WorkflowActions";
import WorkflowList from "components/Workflows/WorkflowList/WorkflowList";
import {ENTITY_TYPES, PAGE_SIZE_CONSTANTS} from "../../../utility/constants/constants";
import { getHighestAccessLevel } from "utility/utility";
import {Row} from "../Row/Row";
import PaginationComponent from "../Pagination/Pagination";
import EmptyScreen from "components/shared/ListPage/emptyScreen";

const EntityList = ({entityName, entityType, user, filterParams, setFilterParams, sharedFilterParams, setSharedFilterParams}) => {
  const dispatch = useDispatch();

  const {projects, projectShares, projectsPageCount, projectSharesPageCount} = useSelector((state) => state.projectsReducer);
  const {datasets, datasetShares, datasetsPageCount, datasetSharesPageCount} = useSelector((state) => state.dataSetReducer);
  const {workflows, workflowShares, workflowsPageCount, workflowSharesPageCount} = useSelector((state) => state.workflowReducer);

  let pageCount = 0
  let sharesPageCount = 0

  if (entityType === ENTITY_TYPES.PROJECT_V2) {
    pageCount = projectsPageCount
    sharesPageCount = projectSharesPageCount
  } else if (entityType === ENTITY_TYPES.DATASET) {
    pageCount = datasetsPageCount
    sharesPageCount = datasetSharesPageCount
  } else if (entityType === ENTITY_TYPES.WORKFLOW) {
    pageCount = workflowsPageCount
    sharesPageCount = workflowSharesPageCount
  }

  useEffect(() => {
    if (entityType === ENTITY_TYPES.PROJECT_V2) {
      filterParams.pageSize = PAGE_SIZE_CONSTANTS.ROWS
      dispatch(projectActions.getProjects(filterParams));
      dispatch(projectActions.getProjectShares(sharedFilterParams))
    } else if (entityType === ENTITY_TYPES.DATASET) {
      filterParams.pageSize = PAGE_SIZE_CONSTANTS.ROWS
      dispatch(datasetActions.getDataSets(filterParams))
      dispatch(datasetActions.getDatasetShares(sharedFilterParams))
    } else if (entityType === ENTITY_TYPES.WORKFLOW) {
      filterParams.pageSize = PAGE_SIZE_CONSTANTS.WORKFLOWS
      dispatch(workflowActions.getWorkflows(filterParams));
      dispatch(workflowActions.getWorkflowShares(sharedFilterParams))
    }
  }, [dispatch, entityType, filterParams, sharedFilterParams])

  return (
    <>
      <div className={"lds_section_header"}>
        <h5>My {entityName}</h5>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {
            projects && projects.length > 0 && entityType === ENTITY_TYPES.PROJECT_V2 ? (
              projects.map((project) => {
                return (
                  <Row
                    entity={project}
                    entityType={ENTITY_TYPES.PROJECT_V2}
                    accessLevel={getHighestAccessLevel(project, user)}
                    isSharedWithMe={false}
                    filterParams={filterParams}
                    key={project.id}
                  />
                )
              })
            ) : null
          }
          {
            projects && projects.length === 0 && entityType === ENTITY_TYPES.PROJECT_V2 ? (
              <div className={"lds_empty_state"}>
                <EmptyScreen entity={ENTITY_TYPES.PROJECT_V2}></EmptyScreen>
              </div>
            ) : null
          }
          {
            datasets && datasets.length > 0 && entityType === ENTITY_TYPES.DATASET ? (
              datasets.map((dataset) => {
                if (dataset?.owner_id) {
                  return (
                    <Row
                      entity={dataset}
                      entityType={ENTITY_TYPES.DATASET}
                      accessLevel={getHighestAccessLevel(dataset, user)}
                      isSharedWithMe={false}
                      filterParams={filterParams}
                      key={dataset.id}
                    />
                  )
                } else {
                  return null
                }
              })
            ) : null
          }
          {
            datasets && datasets.length === 0 && entityType === ENTITY_TYPES.DATASET ? (
              <div className={"lds_empty_state"}>
                <EmptyScreen entity={ENTITY_TYPES.DATASET}></EmptyScreen>
              </div>
            ) : null
          }
          {
            workflows && workflows.length > 0 && entityType === ENTITY_TYPES.WORKFLOW ? (
              < WorkflowList filterParams = {filterParams}/>
            ) : null
          }
          {
            workflows && workflows.length === 0 && entityType === ENTITY_TYPES.WORKFLOW ? (
              <div className={"lds_empty_state"}>
                <EmptyScreen entity={ENTITY_TYPES.WORKFLOW}></EmptyScreen>
              </div>
            ) : null
          }
        </div>
      </div>
      <div className="pagination_container">
        <div className="entity_pagination">
          <PaginationComponent
            setNextPage={() => setFilterParams({...filterParams, page: filterParams.page + 1})}
            setLastPage={() => setFilterParams({...filterParams, page: filterParams.page - 1})}
            page={filterParams.page}
            count={pageCount}
          />
        </div>
      </div>
      <div className={"lds_section_header"}>
        <h5>Shared With Me</h5>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {
            projectShares && projectShares.length > 0 && entityType === ENTITY_TYPES.PROJECT_V2 ?
              (
                projectShares.map((project) => {
                  return (
                    <Row
                      entity={project}
                      entityType={ENTITY_TYPES.PROJECT_V2}
                      accessLevel={getHighestAccessLevel(project, user)}
                      isSharedWithMe={true}
                      filterParams={sharedFilterParams}
                      key={project.id}
                    />
                  )
                })
              ) : null
          }
          {
            projectShares && projectShares.length === 0 && entityType === ENTITY_TYPES.PROJECT_V2 ? (
              <div className={"lds_empty_state"}>
                <p>No projects have been shared with you yet.</p>
              </div>
            ) : null
          }
          {
            datasetShares && datasetShares.length > 0 && entityType === ENTITY_TYPES.DATASET ?
              (
                datasetShares.map((dataset) => {
                  return (
                    <Row
                      entity={dataset}
                      entityType={ENTITY_TYPES.DATASET}
                      accessLevel={getHighestAccessLevel(dataset, user)}
                      isSharedWithMe={true}
                      filterParams={sharedFilterParams}
                      key={dataset.id}
                    />
                  )
                })
              ) : null
          }
          {
            datasetShares && datasetShares.length === 0 && entityType === ENTITY_TYPES.DATASET ? (
              <div className={"lds_empty_state"}>
                <p>No datasets have been shared with you yet.</p>
              </div>
            ) : null
          }
          {
            workflowShares && workflowShares.length > 0 && entityType === ENTITY_TYPES.WORKFLOW ?
              (
                workflowShares.map((workflow) => {
                  return (
                    <Row
                      entity={workflow}
                      entityType={ENTITY_TYPES.WORKFLOW}
                      accessLevel={getHighestAccessLevel(workflow, user)}
                      isSharedWithMe={true}
                      filterParams={sharedFilterParams}
                      key={workflow.id}
                    />
                  )
                })
              ) : null
          }
          {
            workflowShares && workflowShares.length === 0 && entityType === ENTITY_TYPES.WORKFLOW ? (
              <div className={"lds_empty_state"}>
                <p>No workflows have been shared with you yet.</p>
              </div>
            ) : null
          }
        </div>
      </div>
      <div className="pagination_container">
        <div className="entity_pagination">
          <PaginationComponent
            setNextPage={() => setSharedFilterParams({...sharedFilterParams, page: sharedFilterParams.page + 1})}
            setLastPage={() => setSharedFilterParams({...sharedFilterParams, page: sharedFilterParams.page - 1})}
            page={sharedFilterParams.page}
            count={sharesPageCount}
          />
        </div>
      </div>
    </>
  )
}

export default EntityList;
