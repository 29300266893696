import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verify2FA } from "redux/actions/AuthActions";
import * as commonService from "utility/CommonService";
import { Modal, Button } from "react-bootstrap";
import {
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined
} from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import { Card, CardContent, Collapse } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Input } from "../../shared/ui-components/form-components";

const EnableTwoFactorAuth = ({ showModal, handleClose, handleVerify2fa }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      verify2FA({
        body: {
          secret: user.secret_2fa,
          token: code,
        }
      })
    ).then((res) => {
      setCode("");
      if (res.value?.success) {
        commonService.forSuccess(
          "2FA Verified",
          "Success"
        );
      } else {
        commonService.forError(
          "Invalid 2FA Code",
          "Error"
        );
      }
    });
    handleVerify2fa(true);
    handleClose();
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Modal
      show={showModal}
      className="two_factor_auth_enable_modal"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className="border-0 two_factor_auth_enable_header">
          <div className="header_text">Enable 2FA</div>
          <IconButton onClick={handleClose} aria-label="Close">
            <HighlightOff></HighlightOff>
          </IconButton>
        </Modal.Header>
        <Modal.Body className="two_factor_auth_enable_body">
          <label htmlFor='code' className="authenticate_note">
            Please authenticate with 6-digit code
          </label>
          <Card
            className={expanded ? "two_factor_instruction_card expanded" : "two_factor_instruction_card"}>
            <CardContent className="verification_instructions">
              <div expand={expanded} className="show_hide_instruction"
                   onClick={handleExpandClick}>
                {expanded ?
                  (
                    <>
                      <KeyboardArrowUpIcon className="instruction_icon" />
                      Hide verification instructions
                    </>
                  ) :
                  (
                    <>
                      <InfoOutlinedIcon className="instruction_icon" />
                      Show verification instructions
                    </>
                  )
                }
              </div>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent className="instruction_container">
                <div className="step">
                  <div className="step_circle_container">
                    <div className="step_circle">
                      <div className="step_circle_text">
                        1
                      </div>
                    </div>
                  </div>
                  <div className="step_text">
                    If 2FA was enabled previously for this Ludis account, please
                    delete any older entries in your authenticator app.
                    Otherwise, install an authenticator app.
                  </div>
                </div>
                <div className="step">
                  <div className="step_circle_container">
                    <div className="step_circle">
                      <div className="step_circle_text">
                        2
                      </div>
                    </div>
                  </div>
                  <div className="step_text">
                    Add a new secret by scanning the QR or manually typing your
                    secret key.
                  </div>
                </div>
                <div className="step">
                  <div className="step_circle_container">
                    <div className="step_circle">
                      <div className="step_circle_text">
                        3
                      </div>
                    </div>
                  </div>
                  <div className="step_text">
                    Enter the 6-digit code shown in your authenticator to
                    verify.
                  </div>
                </div>
              </CardContent>
            </Collapse>
          </Card>
          <div>
            <div className="secret_label">
              Secret key
            </div>
            {user.secret_2fa ? (
              <div className="secret_code_container">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={user.secret_2fa ? user.secret_2fa : "2FA Secret"}
                  className="secret_code"
                  disabled
                />
                <div className="password_field_eye_icon"
                     onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOutlined></VisibilityOutlined> :
                    <VisibilityOffOutlined></VisibilityOffOutlined>}
                </div>
              </div>
            ) : null}
            {user.secret_2fa_qr ? (
              <div className="secret_qr_container">
                <Paper
                  sx={{
                    width: "200px",
                    height: "225px",
                  }}
                >
                  <img
                    src={user.secret_2fa_qr}
                    height="150"
                    width="150"
                    alt="Secret QR Code for Two Factor Authentication"
                  ></img>
                  <p style={{ paddingTop: "15px" }}>2FA Secret QR Code</p>
                </Paper>
              </div>
            ) : null}
          </div>
          <label htmlFor='code' className="six_digit_code_label">
            6-digit code
          </label>
          <Input
            type="text"
            name="code"
            placeholder="enter 6-digit code here"
            id="code"
            className="six_digit_code_input"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </Modal.Body>
        <Modal.Footer className="border-0 two_factor_auth_enable_footer">
          <Button type="submit" variant="primary" size="sm"
                  className="verify_btn"
                  onClick={(e) => handleSubmit(e)}>Verify</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EnableTwoFactorAuth;