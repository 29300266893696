import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/ProjectsActions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircleIcon from "@mui/icons-material/Circle";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ProjectDatasets } from "./ProjectDatasets";
import { appendToBaseUrl, copyToClipboard } from "utility/CommonService";
import CodeSnippetPopup from "components/shared/Popup/CodeSnippetPopup";
import * as datasetActions from "../../../redux/actions/DataSetActions";
import DeleteConfirmationModal from "components/shared/Modals/DeleteConfirmationModal";
import {
  getFirstFile,
  getPreviousFilePath,
  isImageFilePath,
  setPermissionTooltip,
} from "utility/utility";
import { Tooltip } from "@mui/material";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import ProjectWorkflows from "./ProjectWorkflows";

export const FileNavigator = ({
  goBack,
  handleShowCommitModal,
  accessLevel,
  disableIcon,
  setShowCreateWorkflowModal
}) => {
  const dispatch = useDispatch();
  const NAV_WIDTH = 250;
  const NAV_WIDTH_MAX = 500;
  const NAV_WIDTH_MIN = 120;
  const [width, setWidth] = useState(NAV_WIDTH);

  const { repoContents, project, currentFilePath, projectFilesContent } =
    useSelector((state) => state.projectsReducer);
  const [popupPosition, setPopupPosition] = useState({ top: null, left: null });
  const [showCodeSnippet, setShowCodeSnippet] = useState(false);

  const getRepoContents = (project, item) => {
    if (item?.type === "dir") {
      dispatch(
        actions.getRepoContent({
          body: { project: project, path: item.path },
          id: project.id,
        })
      );
    } else if (item?.type === "file") {
      if (isImageFilePath(item.path)) {
        window.open(item.download_url, "_blank");
      } else {
        dispatch(
          actions.getFileContent({
            body: { project: project, path: item.path },
            id: project.id,
          })
        );
      }
    }
  };
  const handleRefreshRepo = () => {
    dispatch(
      actions.getRepoContent({
        body: {
          project: project,
          path: getPreviousFilePath(currentFilePath),
        },
        id: project.id,
      })
    );
  };

  //open the first file by default when viewing a repo or clicking into a folder
  useEffect(() => {
    let firstFile = getFirstFile(repoContents);
    if (firstFile)
      dispatch(
        actions.getFileContent({
          body: { project: project, path: firstFile.path },
          id: project.id,
        })
      );
  }, [repoContents, project, dispatch]);

  const getDownloadUrl = async (path, id) => {
    dispatch(datasetActions.getLudisUrl({ filePath: path }, id)).then((res) => {
      copyToClipboard(appendToBaseUrl(res.value.ludisUrl));
    });
  };

  const toggleShowSnippet = () => {
    setShowCodeSnippet(!showCodeSnippet);
  };

  const handleShowSnippet = async (event, path, id) => {
    await getDownloadUrl(path, id);
    toggleShowSnippet();
    const rowRect = event.target.getBoundingClientRect();
    setPopupPosition({ top: rowRect.top - 375, left: rowRect.right });
  };

  const deleteFile = (path, project) => {
    dispatch(
      actions.deleteGithubFile({ filePath: path, project: project })
    ).then(() => {
      let newFilePath =
        path.lastIndexOf("/") === -1
          ? ""
          : path.slice(0, path.lastIndexOf("/"));
      dispatch(
        actions.getRepoContent({
          body: { project: project, path: newFilePath },
          id: project.id,
        })
      );
    });
  };

  const onResize = (event, { node, size, handle }) => {
    setWidth(size.width);
  };

  return (
    <Resizable
      minConstraints={[NAV_WIDTH_MIN]}
      maxConstraints={[NAV_WIDTH_MAX]}
      onResize={onResize}
      width={width}
      handle={<div className="border_handle_right"></div>}
    >
      <div className="resize-wrapper">
        <div style={{ width: `${width}px` }} className="file_navigator_wrapper">
          <div className="area_header"></div>
          <div className="editor_toolbar">
            <h5>Project Files</h5>
          </div>
          <div className="nav_assets_wrapper">
            <div className="project_nav_area">
              <div
                className="nav_toolbar"
                style={{ justifyContent: "space-between" }}
              >
                <Tooltip title="Back" placement="top">
                  <ArrowBackIosIcon
                    className={`${
                      !getPreviousFilePath(currentFilePath) &&
                      "toolbar_icn_disabled"
                    }`}
                    onClick={() => goBack()}
                  />
                </Tooltip>
                <div>
                  <Tooltip title="Refresh Navigator" placement="top">
                    <RefreshIcon onClick={handleRefreshRepo} />
                  </Tooltip>
                  <Tooltip
                    title={setPermissionTooltip("edit", accessLevel, "")}
                  >
                    <span>
                      <NoteAddOutlinedIcon
                        className={`add_file ${disableIcon(
                          ["view", "execute"],
                          accessLevel
                        )}`}
                        onClick={handleShowCommitModal}
                      />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="project_files">
                {repoContents.map((item) => {
                  return (
                    <div
                      onClick={() => getRepoContents(project, item)}
                      key={item.path}
                      className={`project_file ${
                        currentFilePath === item.path ? "active" : ""
                      }`}
                    >
                      <div className="project_file_left">
                        {item.type === "dir" ? (
                          <FolderOutlinedIcon />
                        ) : (
                          <InsertDriveFileOutlinedIcon />
                        )}
                        <div className="project_file_name">{item.name}</div>
                      </div>
                      {item.type === "file" && (
                        <div className="project_file_right">
                          {projectFilesContent[item.path]
                            ?.hasUnsavedChanges && (
                            <CircleIcon fontSize="8pt" />
                          )}
                          <DeleteConfirmationModal
                            onConfirm={() => deleteFile(item.path, project)}
                            type={item.type}
                            accessLevel={accessLevel}
                            disableIcon={disableIcon}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <ProjectWorkflows projectId={project.id} setShowCreateWorkflowModal={setShowCreateWorkflowModal} accessLevel={accessLevel} />
            {/*TO DO [ENG-1007] ADD DATASETS LINKING */}
            <div className="project_datasets">
              <ProjectDatasets
                handleShowSnippet={handleShowSnippet}
                getDownloadUrl={getDownloadUrl}
              />
            </div>
            {showCodeSnippet && (
              <CodeSnippetPopup
                toggleShowSnippet={toggleShowSnippet}
                popupPosition={popupPosition}
              />
            )}
          </div>
        </div>
      </div>
    </Resizable>
  );
};

export default FileNavigator;
