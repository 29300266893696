import React, { Component } from 'react';
import Aux from '../Oux/Oux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from "components/shared/ui-components/loader";

import DefaultLayout from "./DefaultLayout";
import UserLayout from "./UserLayout";
import "assets/custom-styles/style.scss";

class Layout extends Component {
    render() {
        return (
            <Aux>
                <div id="wrap">
                    <Loader></Loader>
                    {
                        ((this.props.token && this.props.verified_2fa) || (this.props.token && !this.props.user.enabled_2fa)) ? <UserLayout {...this.props}/> : <DefaultLayout {...this.props}/>
                    }
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        token: state.authReducer.token,
        verified_2fa: state.authReducer.verified_2fa,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));